import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DogSVG from '../images/dog.svg'
import BottomNav from '../components/BottomNav';

const Score = () => {
  return (
    <Box height='100vh'>

      <Box bgcolor='#CC4B50' fontSize='15px' mb='1em' textAlign='center' p='0.5em' display='flex' justifyContent='center' alignItems='center'>
        <PlayCircleOutlineOutlinedIcon /> Your Score
      </Box>

      <Box mt='20px' justifyContent='center' display='flex' position='relative'>
        <img src={DogSVG} height='150px' alt='' />
      </Box>
      <Box fontSize='30px' mb='0.1em' textAlign='center' mt='0'>
        838 DOGS
      </Box>
      <Card sx={{ bgcolor: '#1a1a1a', borderRadius: '0.75em', color: 'white', p: '1.5em', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '20px' }}>
        <Box fontSize='20px' mb='0.5em' textAlign='center'>
          CATS COMMUNITY
        </Box>
        <Box fontSize='14px' mb='0.75em' textAlign='center' color='grey'>
          Home for Telegram OGs
        </Box>
        <Button sx={{
          bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%'
        }}>
          <Typography fontWeight='bold' fontSize='14px'>
            Go to CATS Channel
          </Typography>
        </Button>
      </Card>
      <Box fontSize='20px' fontWeight='bold' mt='1em'>
        Your rewards
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
        <Box display='flex' alignItems='center' fontSize='14px'>
          <AccessTimeOutlinedIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
          Account Age
        </Box>
        <Box fontWeight='bold' fontSize='16px'>
          +838 DOGS
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
        <Box display='flex' alignItems='center' fontSize='14px'>
          <DoneOutlinedIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
          Telegram Premium
        </Box>
        <Box>
          1
        </Box>
      </Box>
      <Box sx={{ width: '100%', bgcolor: 'black' }}>
        {/* <BottomNav /> */}
      </Box>
    </Box >
  )
}

export default Score