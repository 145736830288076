import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DogSVG from '../images/dog.svg'
import BottomNav from '../components/BottomNav';

const InvFriends = () => {
  return (
    <Box height='100vh'
    // border='1px solid white'
    >
      <Box fontSize='30px' mb='1em' textAlign='center' pt='2em'>
        Invite friends and get more DOGS
      </Box>
      <Box my='1em' justifyContent='center' display='flex'>
        <img src={DogSVG} alt='' />
      </Box>
      <Box fontSize='14px' mb='1em' textAlign='center' mt='2em'
      // border='1px solid white'
      >
        Tap on the button to invite your friends
      </Box>
      <Button
        fullWidth sx={{
          bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '7em', textTransform: 'none'
        }} >
        <Typography fontWeight='bold' fontSize='14px'>Invite Friends</Typography>
      </Button>
      <Box sx={{ width: '100%', bgcolor: 'black' }}>
        <BottomNav />
      </Box>
    </Box >
  )
}

export default InvFriends