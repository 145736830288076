import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DogSVG from '../images/dog2.svg'
import BottomNav from '../components/BottomNav';

const RisingStar = () => {
  return (
    <Box height='100vh'>
      <Box display='flex' justifyContent='space-around'>
        <Box border='1px solid white' width='45%' />
        <Box border='1px solid grey' width='45%' />
      </Box>
      <Box fontSize='30px' mb='1em' textAlign='center' pt='1em'>
        Rising Star!
      </Box>

      <Box fontSize='14px' mb='1em' textAlign='center' mt='2em'
      // border='1px solid white'
      >
        You've joined Telegram
      </Box>
      <Box my='1em' justifyContent='center' display='flex' position='relative'>
        <img src={DogSVG} height='250px' alt='' />
      </Box>
      <Box display='flex' flexDirection='column' alignItems='center' position='absolute' left='38%' top='28%'>
        <Box color='#CC4B50' fontSize='160px' fontWeight='bold'>1</Box>

      </Box>
      <Box display='flex' flexDirection='column' alignItems='center' position='absolute' left='35%' top='55%'>
        <Box fontSize='30px'> year ago</Box>
      </Box>

      <Box fontSize='14px' mb='0.1em' textAlign='center' mt='7em'
      // border='1px solid white'
      >
        Your account number is {Telegram?.WebApp?.initDataUnsafe?.user?.id}
      </Box>
      <Box fontSize='14px' mb='1em' textAlign='center'
      // border='1px solid white'
      >
        You're in the Top 90% Telegram users!
      </Box>
      <Button
        fullWidth sx={{
          bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '1em', textTransform: 'none'
        }} >
        <Typography fontWeight='bold' fontSize='14px'>Continue</Typography>
      </Button>
      <Box sx={{ width: '100%', bgcolor: 'black' }}>
        {/* <BottomNav /> */}
      </Box>
    </Box >
  )
}

export default RisingStar