import React from 'react'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';

const CheckAccount = () => {
  const [progress, setProgress] = React.useState(0);

  const navigate = useNavigate();
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
            setTimeout(()=>navigate('/risingstar'),1500);
            return 0;
        }
        const diff = Math.random() * 50;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box height='100vh'>
      <Box fontSize='30px' mb='45vh' >
        Checking your account
      </Box>
      <Box my='1em'>
        <Box display='flex' justifyContent='space-between'>
          Account Age Verified
          <CheckCircleOutlineIcon sx={{ color: progress === 100 ? '#CC4B50' : 'white' }} />
        </Box>
        <LinearProgress color='inherit' variant="determinate" value={progress} sx={{ color: '#CC4B50' }} />
      </Box>
      <Box my='1em'>
        <Box display='flex' justifyContent='space-between'>
          Activity Level Analyzed
          <CheckCircleOutlineIcon sx={{ color: progress === 100 ? '#CC4B50' : 'white' }} />
        </Box>
        <LinearProgress color='inherit' variant="determinate" value={progress} sx={{ color: '#CC4B50' }} />
      </Box>
      <Box my='1em'>

        <Box display='flex' justifyContent='space-between'>
          Telegram Premium Channel
          <CheckCircleOutlineIcon sx={{ color: progress === 100 ? '#CC4B50' : 'white' }} />
        </Box>
        <LinearProgress color='inherit' variant="determinate" value={progress} sx={{ color: '#CC4B50' }} />
      </Box>
      <Box my='1em'>

        <Box display='flex' justifyContent='space-between'>
          OG Status Confirm
          <CheckCircleOutlineIcon sx={{ color: progress === 100 ? '#CC4B50' : 'white' }} />
        </Box>
        <LinearProgress color='inherit' variant="determinate" value={progress} sx={{ color: '#CC4B50' }} />
      </Box>
    </Box>
  )
}

export default CheckAccount