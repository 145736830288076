import React from 'react'

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

const BottomNav = (props) => {

  const [value, setValue] = React.useState(0);
  return (

      <Grid
      position='fixed' width='100vw' left='0' bottom='0' bgcolor='black' height='10vh'
      // border='1px solid yellow'
      // position='absolute'
      >
        <Grid xs={12}
        // border='1px solid white'
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{ bgcolor: 'black', ...props }}
          >
            <BottomNavigationAction sx={{
              color: value === 0 ? 'white' : 'grey',
              '&.Mui-selected': { color: 'white' },
            }} label="Home" icon={<HomeOutlinedIcon />} />
            <BottomNavigationAction sx={{
              color: value === 1 ? 'white' : 'grey',
              '&.Mui-selected': { color: 'white' },
            }} label="Leaderboard" icon={<LeaderboardOutlinedIcon />} />
            <BottomNavigationAction sx={{
              color: value === 2 ? 'white' : 'grey',
              '&.Mui-selected': { color: 'white' },
            }} label="Friends" icon={<PeopleOutlinedIcon />} />
          </BottomNavigation>
        </Grid>
      </Grid>

  )
}

export default BottomNav