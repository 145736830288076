import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DogSVG from '../images/dog.svg'
import BottomNav from '../components/BottomNav';

const Amazing = () => {
  return (
    <Box height='100vh'>
      <Box display='flex' justifyContent='space-around'>
        <Box border='1px solid grey' width='45%' />
        <Box border='1px solid white' width='45%' />
      </Box>
      <Box fontSize='30px' mb='1em' textAlign='center' pt='1em'>
        You are amazing!
      </Box>

      <Box fontSize='14px' mb='1em' textAlign='center' mt='2em'
      // border='1px solid white'
      >
        Here is your DOGS reward
      </Box>
      <Box mt='100px' justifyContent='center' display='flex' position='relative'>
        <img src={DogSVG} height='150px' alt='' />
      </Box>


      <Box fontSize='14px' mb='0.1em' textAlign='center' mt='7em'
      // border='1px solid white'
      >

      </Box>
      <Box fontSize='14px' mb='2em' textAlign='center'
      // border='1px solid white'
      >
        Thanks for your time on Telegram
      </Box>
      <Button
        fullWidth sx={{
          bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '1em',textTransform: 'none'
        }} >
        <Typography fontWeight='bold' fontSize='14px'>Continue</Typography>
      </Button>
      <Box sx={{ width: '100%', bgcolor: 'black' }}>
        {/* <BottomNav /> */}
      </Box>
    </Box >
  )
}

export default Amazing