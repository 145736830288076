import React from 'react'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import no1 from '../images/no1.png'
import no2 from '../images/no2.png'
import no3 from '../images/no3.png'
import BottomNav from '../components/BottomNav';

const WallOfFame = () => {

  const items = Array.from({ length: 30 }, (_, index) => index);

  const getRanking = (index) => {
    switch (index) {
      case 0:
        return <img src={no1} alt='' />;
      case 1:
        return <img src={no2} alt='' />;
      case 2:
        return <img src={no3} alt='' />;
      default:
        return `#${index + 1}`
    }
  }
  return (
    <Box >

      <Box fontSize='24px' mb='0.1em' textAlign='center' mt='0'>
        Telegram Wall of Fame
      </Box>
      <Card sx={{
        bgcolor: '#1a1a1a', borderRadius: '0.75em', color: 'white', p: '0.5em', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '20px',
      }}>
        <Box display='flex' mt='0.5em'>
          <Box>
            <Avatar />
          </Box>
          <Box ml='0.5em'>
            <Box fontSize='14px' mb='0.5em' textAlign='left' >
              Anthony_0714
            </Box>
            <Box fontSize='14px' mb='0.75em' textAlign='left' color='grey'>
              838 DOGS
            </Box>
          </Box>
        </Box>
        <Box fontSize='12px'>
          #4420476
        </Box>
      </Card>
      <Box fontSize='20px' fontWeight='bold' mt='1em' overflow='auto' pb='3em'>
        Your rewards
        {items.map((item, index) => (
          <Box
            key={item}
            display='flex'
            mt='0.5em'
            pt='0.5em'
            alignItems='center'
            justifyContent='space-between'
          // border='1px solid yellow'
          >
            <Box display='flex'>
              <Avatar />
              <Box ml='0.5em'>
                <Box fontSize='14px' mb='0.5em' textAlign='left'>
                  user_{index + 300}
                </Box>
                <Box fontSize='14px' mb='0.75em' textAlign='left' color='grey'>
                  838 DOGS
                </Box>
              </Box>
            </Box>
            <Box fontSize='12px'>
              {getRanking(index)}
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: '100%', bgcolor: 'black' }}>
        <BottomNav />
      </Box>

    </Box >
  )
}

export default WallOfFame