import React, { useEffect , useState} from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
// import Home from './pages/Home';
// import About from './pages/About';
import CheckAccount from "./pages/CheckAccount";
import InvFriends from "./pages/InvFriends";
import RisingStar from "./pages/RisingStar";
import Box from "@mui/material/Box";
import Amazing from "./pages/Amazing";
import Score from "./pages/Score";
import WallOfFame from "./pages/WallOfFame";
import axios from 'axios'


const HOST_API = '';
const axiosInstance = axios.create({ baseURL: HOST_API });


function App() {
    const [inviteCode, setInviteCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [shareUrl, setShareUrl] = useState('');

    useEffect(() => {
        Telegram.WebApp.expand();
    }, []);
    useEffect(() => {
        if (Telegram.WebApp.initDataUnsafe) {
            const param = Object.assign({}, Telegram.WebApp.initDataUnsafe.user);
            param.hash = Telegram.WebApp.initDataUnsafe.hash;
            param.invite_code = Telegram.WebApp.initDataUnsafe.start_param;

            const url = encodeURIComponent(
                "https://t.me/wooool_bot/join?startapp=13245"
            );
            const text = encodeURIComponent("We woooool together");

            setShareUrl(`https://t.me/share/url?url=${url}&text=${text}`);

            axiosInstance
                .post("/signupin", {})
                .then((ret) => {
                    setInviteCode(ret.data.invite_code);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [Telegram.WebApp.initDataUnsafe]);

    return (
        <Box bgcolor="black" color="white" height="100%" p="2em">
            <Router>
                <Box>
                    {/* <Link to="/">Home </Link>
          
          <Link to="/invfriends">InvFriends </Link>
          
          <Link to="/risingstar">Rising Star </Link>
          
          <Link to="/amazing">Amazing </Link>
          
          <Link to="/score">Score </Link>
          
          <Link to="/walloffame">Wall Of Fame </Link> */}

                    <Routes>
                        <Route path="/" element={<CheckAccount />} />
                        <Route path="/invfriends" element={<InvFriends />} />
                        <Route path="/risingstar" element={<RisingStar />} />
                        <Route path="/amazing" element={<Amazing />} />
                        <Route path="/score" element={<Score />} />
                        <Route path="/walloffame" element={<WallOfFame />} />
                    </Routes>
                </Box>
            </Router>
        </Box>
    );
}

export default App;
